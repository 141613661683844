import { render, staticRenderFns } from "./accountTransfer.vue?vue&type=template&id=76dd7ffc&scoped=true&"
import script from "./accountTransfer.vue?vue&type=script&lang=js&"
export * from "./accountTransfer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76dd7ffc",
  null
  
)

export default component.exports